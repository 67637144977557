import React from 'react';
import { lazy } from 'react';

interface RouteConfig {
    path: string;
    element: React.LazyExoticComponent<React.FC<any>> | React.FC<any>;
}

const BusinessCardDashboardPage = lazy(() => import('../pages/businesscard/BusinessCardListPage'));
const CreateBusinessCardPage = lazy(() => import('../pages/businesscard/BusinessCardCreatePage'));
const BusinessCardFolderForm = lazy(() => import('../pages/businesscard/BusinessCardFolderCreatePage'));
const BusinessCardStats = lazy(() => import('../pages/businesscard/BusinessCardStatsPage'));
const BusinessCardBulkCreatePage = lazy(() => import('../pages/businesscard/BusinessCardBulkCreatePage'));
const PublicBusinessCardPage = lazy(() => import('../pages/businesscard/PublicBusinessCardPage'));

const businessCardRoutes: RouteConfig[] = [
  {
    path: 'business-cards',
    element: BusinessCardDashboardPage
  },
  {
    path: 'business-cards/folder/:folderId',
    element: BusinessCardDashboardPage
  },
  {
    path: 'business-cards/create',
    element: CreateBusinessCardPage
  },
  {
    path: 'business-cards/edit/:id',
    element: CreateBusinessCardPage
  },
  {
    path: 'business-cards/bulk-create',
    element: BusinessCardBulkCreatePage
  },
  {
    path: 'business-cards/stats/:id',
    element: BusinessCardStats
  },
  {
    path: 'business-cards/folder/create',
    element: BusinessCardFolderForm
  },
  {
    path: 'business-cards/folder/edit/:id',
    element: BusinessCardFolderForm
  },
  {
    path: '/business-card',
    element: BusinessCardDashboardPage
  },
  {
    path: '/business-card/create-card',
    element: CreateBusinessCardPage
  },
  {
    path: '/business-card/bulk-create',
    element: BusinessCardBulkCreatePage
  }
];

export default businessCardRoutes;