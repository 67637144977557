import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

interface RouteConfig {
    path: string;
    element: React.LazyExoticComponent<React.FC<any>> | React.FC<any>;
}
const DashboardPage = lazy(() => import('../pages/lostandfound/Dashboard'));
const CreateItemPage = lazy(() => import('../pages/lostandfound/CreateItemPage'));
const LostFoundItemStatsPage = lazy(() => import('../pages/lostandfound/ItemDetailPage'));
const QRScanPage = lazy(() => import('../pages/lostandfound/QRScanPage'));
const BatchCreatePage = lazy(() => import('../pages/lostandfound/BatchCreatePage'));

const lostandfoundRoutes: RouteConfig[] = [
        {
                path: '/anylost',
                element: DashboardPage
        },
        {
                path: '/anylost/create-item',
                element: CreateItemPage
        },
        {
                path: '/anylost/edit/:itemId',
                element: CreateItemPage
        },
        {
                path: '/anylost/item/:itemId',
                element: LostFoundItemStatsPage
        },
        {
                path: '/anylost/batch-create',
                element: BatchCreatePage
        }
];

export default lostandfoundRoutes;