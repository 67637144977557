// src/routes/dashboardRoutes.tsx
import React from "react";
import { Navigate } from "react-router-dom";

const DashboardPage = React.lazy(() => import("../pages/dashboard/Dashboard"));
const InventoryList = React.lazy(() => import("../pages/inventories/InventoryList"));
const AddInventory = React.lazy(() => import("../pages/inventories/AddInventory"));
const ComingSoon = React.lazy(() => import("../pages/comingsoon/ComingSoon"));
const PricingPage = React.lazy(() => import("../pages/pricing/PricingPage"));
// const EditInventory = React.lazy(() => import("../pages/inventories/EditInventory"));
const ProfilePage = React.lazy(() => import("../pages/profile/ProfilePage"));
const DashboardAnyLost  = React.lazy(() => import('../pages/lostandfound/Dashboard'));
const RedirectDashboardPage = React.lazy(() => import('../pages/redirect/RedirectDashboard'));
const BusinessCardDashboardPage = React.lazy(() => import('../pages/businesscard/BusinessCardListPage'));

interface RouteConfig {
  path: string;
  element: React.LazyExoticComponent<React.FC<any>> | React.FC<any>;
}

const dashboardRoutes: RouteConfig[] = [
  {
    path: "/dashboard",
    element: DashboardPage,
  },
  {
    path: "/dashboard/settings",
    element: DashboardPage,
  },
  
  // {
  //   path: "/pricing",
  //   element: PricingPage,
  // }, 
  {
    path: "/inventory",
    element: ComingSoon,
  },
  {
    path: "/pricing",
    element: ComingSoon,
  },
  {
    path: "/parking",
    element: ComingSoon,
  },
  {
    path: "/events",
    element: ComingSoon,
  },
  {
    path: "/anylost",
    element: DashboardAnyLost,
  },
  {
    path: "/business-cards",
    element: BusinessCardDashboardPage,
  },
  {
    path: '/redirect',
    element: RedirectDashboardPage
  },
  {
    path: "/profile",
    element: ProfilePage,
  },

  {
    path: "*",
    element: () => <Navigate to="/dashboard" replace />,
  },
];

export default dashboardRoutes;
