// LandingPage.tsx
import React, { useEffect, useRef } from "react";
import "./LandingPage.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useNavigate } from "react-router-dom"; 

const LandingPage: React.FC = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  const configRef = useRef({
    theme: "dark",
    animate: true,
    snap: true,
    start: gsap.utils.random(0, 100, 1),
    end: gsap.utils.random(900, 1000, 1),
    scroll: true,
    debug: false,
  });


  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const config = configRef.current;
    let items: HTMLElement[] = [];
    let scrollerScrub: ScrollTrigger | undefined;
    let dimmerScrub: ScrollTrigger | undefined;
    let chromaEntry: gsap.core.Tween | undefined;
    let chromaExit: gsap.core.Tween | undefined;

    const update = () => {
      document.documentElement.dataset.theme = config.theme;
      document.documentElement.dataset.syncScrollbar = String(config.scroll);
      document.documentElement.dataset.animate = String(config.animate);
      document.documentElement.dataset.snap = String(config.snap);
      document.documentElement.dataset.debug = String(config.debug);
      document.documentElement.style.setProperty(
        "--start",
        config.start.toString()
      );
      document.documentElement.style.setProperty(
        "--hue",
        config.start.toString()
      );
      document.documentElement.style.setProperty(
        "--end",
        config.end.toString()
      );

      if (!config.animate) {
        chromaEntry?.scrollTrigger?.disable(true, false);
        chromaExit?.scrollTrigger?.disable(true, false);
        dimmerScrub?.disable(true, false);
        scrollerScrub?.disable(true, false);
        gsap.set(items, { opacity: 1 });
        gsap.set(document.documentElement, { "--chroma": 0 });
      } else {
        gsap.set(items, { opacity: (i) => (i !== 0 ? 0.2 : 1) });
        dimmerScrub?.enable(true, true);
        scrollerScrub?.enable(true, true);
        chromaEntry?.scrollTrigger?.enable(true, true);
        chromaExit?.scrollTrigger?.enable(true, true);
      }
    };

    if (
      !CSS.supports(
        "(animation-timeline: scroll()) and (animation-range: 0% 100%)"
      )
    ) {
      items = Array.from(document.querySelectorAll(".landing-page-list li"));

      gsap.set(items, {
        opacity: (i) => (i !== 0 ? 0.2 : 1),
      });

      const dimmer = gsap
        .timeline()
        .to(items.slice(1), {
          opacity: 1,
          stagger: 0.5,
        })
        .to(
          items.slice(0, items.length - 1),
          {
            opacity: 0.2,
            stagger: 0.5,
          },
          0
        );

      dimmerScrub = ScrollTrigger.create({
        trigger: items[0],
        endTrigger: items[items.length - 1],
        start: "center center",
        end: "center center",
        animation: dimmer,
        scrub: 0.2,
      });

      const scroller = gsap.timeline().fromTo(
        document.documentElement,
        {
          "--hue": config.start,
        },
        {
          "--hue": config.end,
          ease: "none",
        }
      );

      scrollerScrub = ScrollTrigger.create({
        trigger: items[0],
        endTrigger: items[items.length - 1],
        start: "center center",
        end: "center center",
        animation: scroller,
        scrub: 0.2,
      });

      chromaEntry = gsap.fromTo(
        document.documentElement,
        { "--chroma": 0 },
        {
          "--chroma": 0.3,
          ease: "none",
          scrollTrigger: {
            scrub: 0.2,
            trigger: items[0],
            start: "center center+=40",
            end: "center center",
          },
        }
      );

      chromaExit = gsap.fromTo(
        document.documentElement,
        { "--chroma": 0.3 },
        {
          "--chroma": 0,
          ease: "none",
          scrollTrigger: {
            scrub: 0.2,
            trigger: items[items.length - 2],
            start: "center center",
            end: "center center-=40",
          },
        }
      );
    }

    update();
  }, []);

  const words = [
    "inventory",
    "track",
    "manage",
    "scan",
    "organize",
    "locate",
    "find",
    "recover",
    "store",
    "identify",
    "photos",
    "events",
    "share",
    "connect",
    "network",
    "business",
    "cards",
    "parking",
    "access",
    "control",
    "secure",
    "simplify",
  ];

  const handleTryNowClick = () => {
    const accessToken = localStorage.getItem('accessToken');
    
    if (accessToken) {
      navigate('/dashboard');
    } else {
      navigate('/login');
    }
  };


  return (
    <div ref={containerRef} className="landing-page">
      <header className="landing-page-header">
        <h1 className="landing-page-title fluid">
          All about
          <br />
          QR codes!
        </h1>
      </header>
      <main className="landing-page-main">
        <section className="landing-page-content fluid">
          <h2 className="landing-page-subtitle">
            <span aria-hidden="true">you can&nbsp;</span>
            <span className="sr-only">you can ship things.</span>
          </h2>
          <ul
            className="landing-page-list"
            aria-hidden="true"
            style={{ "--count": "22" } as React.CSSProperties}
          >
            {words.map((word, index) => (
              <li key={index} style={{ "--i": index } as React.CSSProperties}>
                {word}.
              </li>
            ))}
          </ul>
        </section>
        <section className="landing-page-final">
          <h2 className="landing-page-end fluid">Try it now.</h2>
          <button
            className="landing-page-redirect-button"
            onClick={handleTryNowClick}
          >
            <span className="landing-page-arrow"></span>
          </button>
        </section>
      </main>
    </div>
  );
};

export default LandingPage;
