// src/routes/index
import React from "react";
import { redirect } from "react-router-dom";
import { RouteConfig } from "./types";

import authRoutes from "./authRoutes";
import dashboardRoutes from "./dashboardRoutes";
import LandingPage from "../pages/LandingPage";
import pricingRoutes from "./pricingRoutes";
import lostandfoundRoutes from "./lostandfoundRoutes";
import redirectRoutes from "./redirectRoutes";
import businessCardRoutes from "./businesscardRoutes";
import qrinternalRoutes from "./QRinternalroutes";

const routes: RouteConfig[] = [
  {
    path: "/",
    element: LandingPage,
  },
  ...authRoutes,
  ...dashboardRoutes,
  ...pricingRoutes,
  ...lostandfoundRoutes,
  ...redirectRoutes,
  ...businessCardRoutes,
  ...qrinternalRoutes,
];

export default routes;
