import React from 'react';
import { lazy } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

interface RouteConfig {
    path: string;
    element: React.LazyExoticComponent<React.FC<any>> | React.FC<any>;
}

const UnifiedQRRouter = lazy(() => import('../pages/UnifiedQRRouter'));

const qrinternalRoutes: RouteConfig[] = [
        {
                path: '/:qrId',
                element: UnifiedQRRouter 
        },
];

export default qrinternalRoutes;