import React from "react";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const PricingPage = React.lazy(() => import("../pages/pricing/PricingPage"));
const ThankYouPage = React.lazy(() => import("../pages/pricing/ThankYouPage"));



const stripePromise = loadStripe('pk_test_51Qrm7lPZGPMuJaq9YuIKHR5VbCoYB04hRp0Ql4qzGjU1T649cCGeRZSlAAuighZfaK8Io0hwM66VADit9jrLpk4l00qdBsobse');

const StripeWrapper: React.FC<{ Component: React.ComponentType<any> }> = ({ Component }) => {
  if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
    return <div>Stripe configuration error. Please contact support.</div>;
  }
  
  return (
    <Elements stripe={stripePromise}>
      <Component />
    </Elements>
  );
};

interface RouteConfig {
  path: string;
  element: React.LazyExoticComponent<React.FC<any>> | React.FC<any>;
}

const pricingRoutes: RouteConfig[] = [
  {
    path: "/pricing",
    element: () => <StripeWrapper Component={PricingPage} />,
  },
  {
    path: "/pricing/thank-you",
    element: ThankYouPage,
  }
];

export default pricingRoutes;