import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

interface RouteConfig {
    path: string;
    element: React.LazyExoticComponent<React.FC<any>> | React.FC<any>;
}

const RedirectDashboardPage = lazy(() => import('../pages/redirect/RedirectDashboard'));
const CreateRedirectPage = lazy(() => import('../pages/redirect/CreateRedirect'));
const FolderForm = lazy(() => import('../pages/redirect/FolderForm'));
const RedirectStats = lazy(() => import('../pages/redirect/RedirectStats'));
const BatchCreatePage = lazy(() => import('../pages/redirect/BulkCreateRedirect'));
const PublicRedirectPage = lazy(() => import('../pages/redirect/PublicRedirectPage'));

const redirectRoutes: RouteConfig[] = [
  {
    path: 'redirects',
    element: RedirectDashboardPage
  },
  {
    path: 'redirects/folder/:folderId',
    element: RedirectDashboardPage
  },
  {
    path: 'redirects/create',
    element: CreateRedirectPage
  },
  {
    path: 'redirects/edit/:id',
    element: CreateRedirectPage
  },
  {
    path: 'redirects/bulk-create',
    element: BatchCreatePage
  },
  {
    path: 'redirects/stats/:id',
    element: RedirectStats
  },
  {
    path: 'redirects/folder/create',
    element: FolderForm
  },
  {
    path: 'redirects/folder/edit/:id',
    element: FolderForm
  },
  {
    path: '/redirect',
    element: RedirectDashboardPage
  },
  {
    path: '/redirect/create-redirect',
    element: CreateRedirectPage
  },
  {
    path: '/redirect/batch-create',
    element: BatchCreatePage
  }
];

export default redirectRoutes;
